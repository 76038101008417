import {
  AuditOutlined,
  CompassOutlined,
  RadarChartOutlined,
  PieChartOutlined,
  ContainerOutlined,
  PoundOutlined,
  InboxOutlined,
  FormOutlined,
  CalendarOutlined,
  TeamOutlined,
  TagsOutlined,
  ProfileOutlined,
  GoldOutlined,
  CheckSquareOutlined,
  AlertOutlined,
  SolutionOutlined,
  AreaChartOutlined,
  FileSearchOutlined,
  FileAddOutlined,
  HomeOutlined,
  BarChartOutlined,
  BoxPlotOutlined,
  NodeIndexOutlined,
  AppstoreOutlined,
  SettingOutlined,
} from "@ant-design/icons";
import { SITE_URL } from "../config";
import WandIcon from "../features/grant-writer/components/WandIcon";

export const TAGS = "tags";

export const people = (orgId) => ({
  id: "people",
  href: `/people?view=${orgId}`,
  icon: <TeamOutlined />,
  label: "People",
});

export const getDefaultFeatures = (orgId) => [
  {
    id: "insights",
    href: "/reporting",
    icon: <PieChartOutlined />,
    label: "Reporting",
    restrictedAccess: true,
  },
  {
    id: "calendar",
    href: `/project-calendar?view=${orgId}`,
    icon: <CalendarOutlined />,
    label: "Calendar",
  },
  people(orgId),
  {
    id: "tags",
    href: "/tags",
    icon: <TagsOutlined />,
    label: "Tags",
  },
];

const partners = {
  id: "partners",
  href: "/partners",
  icon: <ProfileOutlined />,
  label: "Partners",
};

const applications = {
  id: "applications",
  href: "/surveys",
  icon: <InboxOutlined />,
  label: "Application Forms",
};

const feedback = {
  id: "feedback",
  href: "/surveys",
  icon: <ContainerOutlined />,
  label: "Progress/Feedback Forms",
};

export const funderActivities = {
  id: "activities",
  href: "/funder/activities",
  icon: <SolutionOutlined />,
  label: "Events & Activities",
};

export const serviceDirectory = {
  id: "directory",
  href: "/funder/directories",
  icon: <AuditOutlined />,
  label: "Service Directories",
};

export const funderTemplates = {
  id: "templates",
  icon: <AuditOutlined />,
  label: "Templates",
  href: "/funder/templates",
};

export const funderApplications = {
  ...applications,
  href: "/funder/surveys?formsFor=organisations&type=application",
};
export const funderFeedback = {
  ...feedback,
  href: "/funder/surveys?formsFor=organisations&type=progress-report",
};
export const funderPartners = { ...partners, href: "/funder/partners" };

export const fundReport = {
  id: "fundReport",
  href: "/funder/funding-report",
  icon: <AreaChartOutlined />,
  label: "Funding Report",
};

export const funds = {
  id: "funds",
  href: "/funder/funds",
  icon: <GoldOutlined />,
  label: "Funds",
};

export const allApplications = {
  id: "allApplications",
  href: "/funder/applications",
  icon: <InboxOutlined />,
  label: "All Applications",
};

export const funderReporting = {
  id: "reporting",
  icon: <AreaChartOutlined />,
  label: "Aggregate Reports",
  href: "/funder/measures",
  requiresUpgrade: true,
  planFeature: "Monitor",
};

export const funderSurveys = {
  id: "surveys",
  icon: <FormOutlined />,
  label: "Surveys",
  href: "/funder/surveys?formsFor=individuals",
  requiresUpgrade: true,
  planFeature: "Monitor",
};

export const volunteerRequestFeature = {
  id: "coronavirus",
  href: "/volunteer-requests",
  icon: <AlertOutlined />,
  label: "Volunteer Requests",
};

export const coronavirusFeature = {
  ...volunteerRequestFeature,
  href: "/coronavirus",
};

export const registrationForms = {
  id: "registrationForms",
  href: "/forms",
  icon: <FileAddOutlined />,
  label: "Registration Forms",
};

export const flows = {
  id: "flows",
  href: "/flows",
  icon: <NodeIndexOutlined />,
  label: "Flows",
};

export const organisationFeatures = [
  {
    id: "resources",
    href: "/resources",
    icon: <HomeOutlined />,
    label: "Room Bookings",
  },
  partners,
  registrationForms,
  flows,
  {
    id: "surveys",
    href: "/surveys",
    icon: <FormOutlined />,
    label: "Surveys/Outcomes",
  },
  {
    id: "payments",
    href: "/payments-dashboard",
    icon: <PoundOutlined />,
    label: "Payments",
  },
  {
    id: "foodChoices",
    href: `https://food.${SITE_URL}`,
    icon: <GoldOutlined />,
    label: "Food Choices",
  },
  {
    id: "checkInCustomers",
    href: "/tables",
    icon: <CheckSquareOutlined />,
    label: "Check people in",
  },
  applications,
  feedback,
  {
    id: "cases",
    href: "/cases",
    icon: <FileSearchOutlined />,
    label: "Cases",
  },
];

export const bookingReports = (programmeId) => ({
  id: "reports",
  href: `/funder/programme/${programmeId}/book?view=explore`,
  icon: <BarChartOutlined />,
  label: "Bookings Report",
});

export const fundingFeatures = [
  {
    id: "grantWriter",
    href: "/grant-writer",
    icon: <WandIcon />,
    label: "AI Writing",
    isAIFeature: true,
    isPippin: true,
  },
  {
    id: "documents",
    href: "/documents",
    icon: <FileAddOutlined />,
    label: "Document Search",
    isAIFeature: true,
  },
  {
    id: "funding",
    href: "/funding",
    icon: <InboxOutlined />,
    label: "Manage Funders",
  },
];

export const infrastructureGrants = {
  id: "infrastructure-grants",
  href: "/area/grant-support",
  icon: <CompassOutlined />,
  label: "Local Grant Support",
};

export const grantManagementFeatures = [
  funderApplications,
  funderFeedback,
  funderReporting,
];

export const partnerCRMFeatures = [funderPartners, funderTemplates];

export const fundingSupportFeatures = [
  ...fundingFeatures.filter((item) => item.isAIFeature),
  infrastructureGrants,
];

export const communityFeatures = (organisation, programmeId) => [
  funderActivities,
  bookingReports(programmeId),
  serviceDirectory,
  people(organisation),
  coronavirusFeature,
];

export const analyseFeatures = [
  {
    id: "Explore Data",
    icon: <BoxPlotOutlined />,
    label: "Explore Data",
    href: "/funder/explore-data",
    requiresUpgrade: true,
    planFeature: "Analyse",
  },
  {
    id: "Survey Analysis",
    icon: <FileSearchOutlined />,
    label: "Survey Analysis",
    href: "/funder/survey-analysis",
    requiresUpgrade: true,
    planFeature: "Analyse",
  },
  {
    id: "localMapping",
    icon: <RadarChartOutlined />,
    label: "Local Mapping",
    href: "/funder/local-mapping",
    requiresUpgrade: true,
    planFeature: "Analyse",
  },
  {
    id: "venues",
    icon: <CompassOutlined />,
    label: "Geospatial Analysis",
    href: "/funder/venues",
    requiresUpgrade: true,
    planFeature: "Analyse",
  },
];

export const FEATURE_GROUPS = {
  core: {
    id: "core",
    label: "Core Features",
    icon: <AppstoreOutlined />,
    isCollapsible: true,
    defaultOpen: true,
    features: ["insights", "calendar", "people", "tags"],
  },
  forms: {
    id: "forms",
    label: "Forms & Surveys",
    icon: <FormOutlined />,
    isCollapsible: true,
    defaultOpen: false,
    features: ["registrationForms", "flows", "surveys"],
  },
  funding: {
    id: "funding",
    label: "Funding & AI",
    icon: <PoundOutlined />,
    isCollapsible: true,
    defaultOpen: false,
    features: ["grantWriter", "funding", "documents"],
    isAIGroup: true,
  },
  stakeholders: {
    id: "stakeholders",
    label: "Stakeholders",
    icon: <TeamOutlined />,
    isCollapsible: true,
    defaultOpen: false,
    features: ["coronavirus", "partners", "cases"],
  },
  operations: {
    id: "operations",
    label: "Operations",
    icon: <SettingOutlined />,
    isCollapsible: true,
    defaultOpen: false,
    features: ["resources", "payments", "checkInCustomers", "foodChoices"],
  },
};
