import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import {
  BuildOutlined,
  DeploymentUnitOutlined,
  PoundOutlined,
  SnippetsOutlined,
} from "@ant-design/icons";
import { Menu } from "antd";
import PropTypes from "prop-types";
import { useRouter } from "next/router";

import { getAllFunderFeatures } from "@/constants/funder-features";
import signOut from "./SignOut";
import OrgContext from "./orgContext";
import { defaultFeatures } from "./OrganisationFeatureSettings";

import SidebarFooter from "./sidebar/SidebarFooter";
import OrganisationSection from "./sidebar/OrganisationSection";
import SidebarHeader from "./sidebar/SidebarHeader";
import SearchItem from "./sidebar/SearchItem";
import SidebarItem from "./sidebar/SidebarItem";
import SidebarGroup from "./sidebar/SidebarGroup";

import Segmented from "./Segmented";

import {
  getDefaultFeatures,
  organisationFeatures,
  coronavirusFeature,
  volunteerRequestFeature,
  fundingFeatures,
  FEATURE_GROUPS,
} from "../constants/features";

/**
 * Get available (non-restricted) features & mark if upgrade required for those not on the plan
 * @param {boolean} restrictedAccess
 * @param {string[]} planFeatures
 * @param {import("@/types").Feature[]} features
 * @returns {import("@/types").Feature[]}
 */
const getAvailableFeatures = (restrictedAccess, planFeatures, features) =>
  features
    .filter((feature) => !(restrictedAccess && feature.restrictedAccess))
    .map((feature) => {
      if (
        feature.requiresUpgrade &&
        !planFeatures?.includes(feature?.planFeature?.toLowerCase() ?? "")
      ) {
        return {
          ...feature,
          href: `/funder/upgrade?feature=${feature.planFeature}`,
          isAvailable: false,
        };
      }
      return feature;
    });

const FUNDER_VIEW = "funder";
const ORG_VIEW = "org";

const AppMenu = ({
  features,
  collapsed,
  setCollapsed,
  onChangeSelected,
  orgId,
  selectOrgId,
  programmeId,
  details,
}) => {
  const { volunteerAdminPages, networks, multipleAccounts, funder, orgData } =
    details;

  const { restrictedAccess, plan, user } = useContext(OrgContext);

  // Add this check for form reviewer
  const isFormReviewer = orgData?.formReviewers?.[user?._id];

  const calculatedFeatures = [];
  if (networks.length > 0) {
    calculatedFeatures.push("networks");
  }
  if (volunteerAdminPages?.length > 0 && !features?.includes("coronavirus")) {
    calculatedFeatures.push("coronavirus");
  }

  const allFeatures = [...calculatedFeatures, ...features];

  const [funderView, setFunderView] = useState(funder);
  useEffect(() => {
    setFunderView(funder);
  }, [funder]);
  const router = useRouter();

  const [newFeatures, setNewFeatures] = useState({});
  const currentFeatures = useRef();

  useEffect(() => {
    currentFeatures.current;
    // find the new features
    if (currentFeatures.current && currentFeatures.current.length > 1) {
      setNewFeatures(
        allFeatures.reduce((acc, feature) => {
          if (!currentFeatures.current?.includes(feature)) {
            acc[feature] = true;
          }
          return acc;
        }, {}),
      );
    }
    currentFeatures.current = allFeatures;
  }, [JSON.stringify(allFeatures)]);

  const defaults = getDefaultFeatures(orgId);
  const allFeaturesSet = new Set([...allFeatures]);

  const featuresToUse = [
    ...defaults,
    ...organisationFeatures,
    volunteerAdminPages?.length > 0
      ? coronavirusFeature
      : volunteerRequestFeature,
  ].filter((feature) => allFeaturesSet.has(feature.id));

  const yourOrgFeatures = getAvailableFeatures(
    restrictedAccess,
    plan?.features,
    featuresToUse,
  );

  const yourFundingFeatures = fundingFeatures.filter(
    (feature) =>
      features.includes(feature.id) && allFeaturesSet.has(feature.id),
  );

  const createSidebarItems = (feats) => (
    <>
      {feats
        .filter(
          (feature) =>
            !funderView ||
            feature.href?.includes("network") ||
            orgData?.funderFeatures?.includes(feature.id),
        )
        .map((feature) => (
          <SidebarItem
            key={feature.id}
            href={feature.href}
            icon={feature.icon}
            label={orgData?.featureLexicon?.[feature.id] || feature.label}
            isNew={newFeatures[feature.id]}
            collapsed={collapsed}
            onClick={onChangeSelected}
            isAIFeature={feature.isAIFeature}
            isPippin={feature.isPippin}
            upgradeFeature={
              feature.requiresUpgrade &&
              feature.isAvailable !== undefined &&
              !feature.isAvailable
            }
          />
        ))}
    </>
  );

  const enabledFeatureGroups = useMemo(() => {
    if (!orgData?._id && !programmeId) {
      return [];
    }
    const allFeatureGroups = getAllFunderFeatures(orgId, programmeId);

    const result = Object.keys(allFeatureGroups).filter((featureGroup) => {
      const featuresInGroup = allFeatureGroups[featureGroup]?.features.map(
        (feature) => feature.id,
      );
      const enabledFeaturesInGroup = featuresInGroup?.filter((feature) =>
        orgData?.funderFeatures?.includes(feature),
      );
      return enabledFeaturesInGroup?.length > 0;
    });

    // Check if there's only one enabled feature group
    const hasOnlyOneGroup = result.length === 1;

    return Object.fromEntries(
      result.map((featureGroup) => [
        featureGroup,
        {
          ...allFeatureGroups[featureGroup],
          // Set defaultOpen to true if there's only one group
          defaultOpen: hasOnlyOneGroup
            ? true
            : allFeatureGroups[featureGroup].defaultOpen,
        },
      ]),
    );
  }, [orgData, programmeId]);

  const renderFeatureGroups = (featuresToUse) =>
    Object.entries(FEATURE_GROUPS).map(([groupKey, group]) => {
      const groupFeatures = [...featuresToUse, ...yourFundingFeatures].filter(
        (feature) => group.features.includes(feature.id),
      );

      if (groupFeatures.length === 0) return null;

      return (
        <SidebarGroup
          key={groupKey}
          label={group.label}
          icon={group.icon}
          isCollapsible={group.isCollapsible}
          defaultOpen={group.defaultOpen}
          showAddFeatures
          // collapsed={collapsed}
          hideText={collapsed}
          borderTop
        >
          {groupFeatures.map((feature) => (
            <SidebarItem
              key={feature.id}
              href={feature.href}
              icon={feature.icon}
              label={orgData?.featureLexicon?.[feature.id] || feature.label}
              collapsed={collapsed}
              onClick={onChangeSelected}
              isNew={newFeatures[feature.id]}
              isPippin={feature.isPippin}
              isAIFeature={feature.isAIFeature}
              upgradeFeature={
                feature.requiresUpgrade &&
                feature.isAvailable !== undefined &&
                !feature.isAvailable
              }
            />
          ))}
        </SidebarGroup>
      );
    });

  // If user is a form reviewer, return minimal menu
  if (isFormReviewer) {
    // Get the last assigned form ID for the link
    const assignedForms = orgData?.formReviewers?.[user?._id]?.assignedForms;
    const lastFormId = assignedForms?.[assignedForms?.length - 1];
    const reviewerLink = lastFormId
      ? `/form-responses/${lastFormId}/review`
      : "/form-responses";

    return (
      <div className="h-full flex flex-col fixed top-0 left-0 border-r shadow-lg border-primary print:hidden print:w-0">
        <SidebarHeader
          orgId={orgId}
          collapsed={collapsed}
          onCollapse={() => setCollapsed(!collapsed)}
          link={reviewerLink}
        />
        <Menu
          className={`${collapsed ? "w-16" : "w-80"} overflow-y-auto overflow-x-hidden flex flex-col flex-1`}
          inlineCollapsed={collapsed}
          mode="inline"
          suppressHydrationWarning
        >
          <div className="flex flex-col h-full justify-between">
            <div>
              <SidebarItem
                key="form-responses"
                href={
                  lastFormId
                    ? `/form-responses/${lastFormId}/review`
                    : "/form-responses"
                }
                icon={<SnippetsOutlined />}
                label="Responses"
                collapsed={collapsed}
                onClick={onChangeSelected}
              />
            </div>
            {!collapsed && (
              <div className="flex flex-col gap-2 w-full px-4 mb-4">
                <OrganisationSection
                  name={orgData?.Name}
                  logo={orgData?.publicDetails?.logo}
                  multipleAccounts={multipleAccounts}
                  onSwitchAccount={selectOrgId}
                  onSignout={signOut}
                />
                <SidebarFooter onSignout={signOut} isFormReviewer />
              </div>
            )}
          </div>
        </Menu>
      </div>
    );
  }

  return (
    <div className="h-full flex flex-col fixed top-0 left-0 border-r shadow-lg border-primary print:hidden print:w-0">
      <SidebarHeader
        orgId={orgId}
        funderView={funderView}
        collapsed={collapsed}
        onCollapse={() => setCollapsed(!collapsed)}
      />
      <Menu
        className={`${collapsed ? "w-16" : "w-80"} overflow-y-auto overflow-x-hidden flex flex-col flex-1`}
        inlineCollapsed={collapsed}
        mode="inline"
        suppressHydrationWarning
      >
        <div className="flex flex-col justify-between flex-1">
          <div className="flex flex-col space-y-0">
            {!collapsed && <SearchItem />}

            {!funderView && renderFeatureGroups(yourOrgFeatures)}

            {funderView &&
              enabledFeatureGroups &&
              Object.keys(enabledFeatureGroups).map((featureGroup) => (
                <SidebarGroup
                  borderTop
                  hideText={collapsed}
                  icon={enabledFeatureGroups[featureGroup].icon}
                  label={
                    orgData?.featureLexicon?.[featureGroup] ||
                    enabledFeatureGroups[featureGroup].label
                  }
                  key={featureGroup}
                  isCollapsible
                  defaultOpen={enabledFeatureGroups[featureGroup].defaultOpen}
                  showAddFeatures={false}
                >
                  {createSidebarItems(
                    enabledFeatureGroups[featureGroup].features,
                  )}
                </SidebarGroup>
              ))}

            {networks.length > 0 && !restrictedAccess && (
              <SidebarGroup
                key="networks"
                label="Networks"
                icon={<DeploymentUnitOutlined className="text-lg" />}
                collapsed={collapsed}
                isCollapsible
              >
                {createSidebarItems(
                  networks.map((network) => ({
                    id: network._id,
                    href: `/network?id=${network._id}`,
                    label: network.title,
                  })),
                )}
              </SidebarGroup>
            )}
          </div>
          {!collapsed && (
            <div className="flex flex-col gap-2 w-full px-4 mb-4">
              <OrganisationSection
                name={orgData?.Name}
                logo={orgData?.publicDetails?.logo}
                multipleAccounts={multipleAccounts}
                onSwitchAccount={selectOrgId}
                onSignout={signOut}
              />
              {funder && (
                <Segmented
                  options={[
                    {
                      label: "Organisation",
                      value: ORG_VIEW,
                    },
                    {
                      label: "Funder",
                      value: FUNDER_VIEW,
                    },
                  ]}
                  value={funderView ? FUNDER_VIEW : ORG_VIEW}
                  onChange={(val) => setFunderView(val === FUNDER_VIEW)}
                  className="w-full"
                />
              )}
              <SidebarFooter onSignout={signOut} isFormReviewer={false} />
            </div>
          )}
        </div>
      </Menu>
    </div>
  );
};

AppMenu.propTypes = {
  features: PropTypes.arrayOf(PropTypes.string),
  collapsed: PropTypes.bool.isRequired,
  setCollapsed: PropTypes.func.isRequired,
  onChangeSelected: PropTypes.func.isRequired,
  orgId: PropTypes.string,
  selectOrgId: PropTypes.func.isRequired,
  programmeId: PropTypes.string,
  details: PropTypes.shape({
    networks: PropTypes.arrayOf(PropTypes.object),
    multipleAccounts: PropTypes.array,
    funder: PropTypes.bool,
    orgData: PropTypes.object.isRequired,
    volunteerAdminPages: PropTypes.arrayOf(PropTypes.object),
  }).isRequired,
};

AppMenu.defaultProps = {
  features: defaultFeatures,
  orgId: null,
  programmeId: null,
};

export default AppMenu;
