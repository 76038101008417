import React, { useState } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { PlusOutlined, UpOutlined, DownOutlined } from "@ant-design/icons";
import { Tooltip, Button } from "antd";
import Link from "next/link";

const FEATURES_LINK = "/settings?tab=features";

function SidebarGroup({
  label,
  icon,
  children,
  showAddFeatures,
  collapsed,
  isCollapsible,
  showHeader,
  defaultOpen,
  className,
  hideText,
  borderTop,
}) {
  const [open, setOpen] = useState(defaultOpen || false);
  const panelId = label
    ? `panel-${label.toLowerCase().replace(/\s/g, "-")}`
    : "panel";
  const items = React.Children.toArray(children);

  const content = (
    <div
      className={classNames(
        "flex flex-row justify-between w-full py-2 pl-4 pr-5",
        {
          "border-t border-gray-100": borderTop,
        },
      )}
    >
      <div className="text-sm flex flex-row items-center space-x-3 text-gray-700">
        <div className="text-primary">{icon}</div>
        {!hideText && (
          <h5 className="mb-0.5 font-medium tracking-wide uppercase text-xs">
            {label}
          </h5>
        )}
      </div>
      <div className="flex flex-row space-x-1 -mt-0.5">
        {showAddFeatures && !hideText && (
          <Tooltip placement="right" title="Toggle features">
            <Link href={FEATURES_LINK} onClick={(e) => e.stopPropagation()}>
              <div className="text-gray-500 p-1.5 cursor-pointer transition-all duration-300 hover:text-accent">
                <PlusOutlined className="text-xs" />
              </div>
            </Link>
          </Tooltip>
        )}
        {isCollapsible && !hideText && (
          <div className="text-gray-500 p-1.5 cursor-pointer transition-colors duration-300 hover:text-accent">
            {open ? (
              <UpOutlined className="text-xs" />
            ) : (
              <DownOutlined className="text-xs" />
            )}
          </div>
        )}
      </div>
    </div>
  );

  if (collapsed) {
    return (
      <div className={classNames("flex flex-col", className)}>
        <Tooltip placement="right" title={label}>
          <div className="flex items-center justify-center py-2 text-primary">
            {icon}
          </div>
        </Tooltip>
        {open && items}
      </div>
    );
  }

  return (
    <div className={classNames("flex flex-col", className)}>
      {isCollapsible && (
        <button
          className="flex flex-row items-center justify-between font-medium cursor-pointer transition-colors duration-300 hover:bg-gray-50"
          onClick={() => setOpen(!open)}
          aria-expanded={open}
          aria-controls={panelId}
          aria-label={`${label || "Section"} section, ${open ? "expanded" : "collapsed"}`}
          type="button"
        >
          {content}
        </button>
      )}
      {((isCollapsible && open) || !isCollapsible) && (
        <div
          id={panelId}
          role="region"
          aria-labelledby={`${panelId}-button`}
          className={classNames("flex flex-col space-y-1 pb-2 pr-4", {
            "ml-8": !collapsed, // Align with header text
            "ml-3": collapsed,
          })}
        >
          {items}
        </div>
      )}
    </div>
  );
}

SidebarGroup.propTypes = {
  label: PropTypes.string.isRequired,
  icon: PropTypes.node.isRequired,
  collapsed: PropTypes.bool,
  showAddFeatures: PropTypes.bool,
  children: PropTypes.node,
  isCollapsible: PropTypes.bool,
  showHeader: PropTypes.bool,
  className: PropTypes.string,
  defaultOpen: PropTypes.bool,
  hideText: PropTypes.bool,
  borderTop: PropTypes.bool,
};

SidebarGroup.defaultProps = {
  collapsed: false,
  showAddFeatures: false,
  children: undefined,
  isCollapsible: false,
  showHeader: true,
  defaultOpen: false,
  className: undefined,
  hideText: false,
  borderTop: false,
};

export default SidebarGroup;
