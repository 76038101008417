/* eslint-disable react/prop-types */
import { Switch, Tooltip } from "antd";
import { SITE_NAME } from "../config";
import { FEATURE_GROUPS } from "../constants/features";

type FeatureDetail = {
  name: string;
  description: string;
};

type FeatureDetails = {
  [key: string]: FeatureDetail;
};

const featureDetails: FeatureDetails = {
  search: {
    name: "Search",
    description: "Search for people, tags, and events",
  },
  insights: {
    name: "Reporting",
    description: "Automatically create reports from your data",
  },
  calendar: {
    name: "Calendar",
    description: "Create a timetable of sessions, take registers and bookings",
  },
  people: {
    name: "People",
    description: "Manage your members and volunteers",
  },
  tags: {
    name: "Tags",
    description: "Create tags to organise your data",
  },
  coronavirus: {
    name: "Volunteer Requests",
    description: "Create volunteer requests and manage volunteers",
  },
  partners: {
    name: "Partners",
    description: "Manage your partners and their data",
  },
  surveys: {
    name: "Surveys/Outcomes",
    description: "Create surveys and collect responses",
  },
  payments: {
    name: "Payments",
    description: "Track and reconcile payments from your members",
  },
  funding: {
    name: "Funding",
    description:
      "View your previous grant applications and responses to funders",
  },
  checkInCustomers: {
    name: "Check-in members",
    description: "Check-in members at the front door",
  },
  foodChoices: {
    name: "Food stock",
    description: "Manage food stock",
  },
  grantWriter: {
    name: "Grant Writer",
    description: "Automatically generate grant applications",
  },
  documents: {
    name: "Documents",
    description: "Store documents and ask questions about them",
  },
  resources: {
    name: "Room Bookings",
    description:
      "Create Rooms, attach them to events, and take bookings for them through Plinth",
  },
  cases: {
    name: "Cases",
    description: "Manage your cases",
  },
  flows: {
    name: "Flows",
    description:
      "Connect registration forms to surveys and reminders in quick links.",
  },
  registrationForms: {
    name: "Registration Forms",
    description: "Create registration forms to collect data from people.",
  },
};

const groupLabels = {
  core: `These features are enabled by default for all accounts. They're the best option for getting started with ${SITE_NAME}.`,
  forms:
    "These features help you create registration forms to collect information from your members, volunteers and partners.",
  funding:
    "These features help you manage your grant applications and responses to funders.",
  stakeholders:
    "These features help you manage your partners, cases and volunteer requests.",
  operations: "These features help you manage your operations.",
};

export const defaultFeatures = ["insights", "calendar", "people", "tags"];
type OrganisationFeatureSettingsProps = {
  features: string[];
  handleFeatureChange: (params: { name: string; checked: boolean }) => void;
  featureDetails: FeatureDetails;
};

const OrganisationFeatureSettings: React.FC<
  OrganisationFeatureSettingsProps
> = ({ features, handleFeatureChange }) => (
  <>
    {Object.entries(FEATURE_GROUPS).map(([groupKey, group]) => (
      <div key={groupKey} className="mb-8">
        <h2 className="font-medium text-lg mt-0 mb-2 flex items-center space-x-4 justify-between">
          <div>
            {group.icon} {group.label}
          </div>
          {group.isAIGroup && (
            <Tooltip title="These features use artificial intelligence">
              <div className="rounded-md bg-blue-100 text-blue-800 px-2 ml-2 text-xs">
                AI
              </div>
            </Tooltip>
          )}
        </h2>
        <p className="mt-1 mb-4 text-gray-700">{groupLabels[groupKey]}</p>
        <div className="w-full">
          {group.features.map((feature) => (
            <div
              key={feature}
              className="flex items-center border-b border-gray-200 py-2"
            >
              <Switch
                onChange={(checked) =>
                  handleFeatureChange({ name: feature, checked })
                }
                checked={features.includes(feature)}
                disabled={groupKey === "core" && features.includes(feature)}
                className="mr-4"
                id={`switch-${feature}`}
                aria-label={featureDetails[feature].name}
                aria-checked={features.includes(feature)}
              />
              <label
                htmlFor={`switch-${feature}`}
                className="flex-1 cursor-pointer"
              >
                <p className="my-0 font-medium">
                  {featureDetails[feature].name}
                </p>
                <p className="text-gray-500 text-sm my-0">
                  {featureDetails[feature].description}
                </p>
              </label>
            </div>
          ))}
        </div>
      </div>
    ))}
  </>
);

export default OrganisationFeatureSettings;
