import {
  BarChartOutlined,
  BoxPlotOutlined,
  CompassOutlined,
  ContactsOutlined,
  FileProtectOutlined,
  FileSearchOutlined,
  PoundOutlined,
  RadarChartOutlined,
  TeamOutlined,
} from "@ant-design/icons";
import {
  bookingReports,
  coronavirusFeature,
  funderActivities,
  funderApplications,
  funderFeedback,
  funderPartners,
  funderReporting,
  funderTemplates,
  fundingFeatures,
  infrastructureGrants,
  people,
  serviceDirectory,
  fundReport,
  funds,
  allApplications,
} from "./features";

export const communityFeatures = (orgId, programmeId) => [
  funderActivities,
  bookingReports(programmeId),
  serviceDirectory,
  people(orgId),
  coronavirusFeature,
];

export const fundingSupportFeatures = [
  ...fundingFeatures.filter((item) => item.isAIFeature),
  infrastructureGrants,
];

export const partnerCRMFeatures = [funderPartners, funderTemplates];

export const grantManagementFeatures = [
  funderApplications,
  funderFeedback,
  funds,
  allApplications,
  // fundReport,
];

export const analyseFeatures = [
  funderReporting,
  {
    id: "Explore Data",
    icon: <BoxPlotOutlined />,
    label: "Explore Data",
    href: "/funder/explore-data",
    requiresUpgrade: true,
    planFeature: "Analyse",
  },
  {
    id: "Survey Analysis",
    icon: <FileSearchOutlined />,
    label: "Survey Analysis",
    href: "/funder/survey-analysis",
    requiresUpgrade: true,
    planFeature: "Analyse",
  },
  {
    id: "localMapping",
    icon: <RadarChartOutlined />,
    label: "Local Mapping",
    href: "/funder/local-mapping",
    requiresUpgrade: true,
    planFeature: "Analyse",
  },
  {
    id: "venues",
    icon: <CompassOutlined />,
    label: "Geospatial Analysis",
    href: "/funder/venues",
    requiresUpgrade: true,
    planFeature: "Analyse",
  },
];

export const getAllFunderFeatures = (orgId, programmeId) => ({
  community: {
    features: communityFeatures(orgId, programmeId),
    label: "Community Pages",
    icon: <TeamOutlined className="text-lg" />,
    defaultOpen: true,
  },
  donorPipeline: {
    features: fundingSupportFeatures,
    label: "AI Funding Support",
    icon: <PoundOutlined className="text-lg" />,
    defaultOpen: false,
  },
  partnerCRM: {
    features: partnerCRMFeatures,
    label: "Partner CRM",
    icon: <ContactsOutlined className="text-lg" />,
    defaultOpen: false,
  },
  grantManagement: {
    features: grantManagementFeatures,
    label: "Grant Management",
    icon: <FileProtectOutlined className="text-lg" />,
    defaultOpen: false,
  },
  analyse: {
    features: analyseFeatures,
    label: "Impact Data Analysis",
    icon: <BarChartOutlined className="text-lg" />,
    defaultOpen: false,
  },
});
