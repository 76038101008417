import { BUILD_LEVEL } from "fire";

// Bucking the trend on constants with functions to return correct index for build level
// Doing all this so I don't need to constantly ctrl + f to switch to candidate indexes.

const PRODUCTION = "production";

export const interactionsIndex = () => BUILD_LEVEL === PRODUCTION ? "interactions" : "staging-interactions";

export const membersIndex = () => BUILD_LEVEL === PRODUCTION ? "members" : "staging-members"

export const volunteersIndex = () => BUILD_LEVEL === PRODUCTION ? "volunteers" : "staging-volunteers";

export const fundingIndex = () => BUILD_LEVEL === PRODUCTION ? "funding" : "staging-funding";

export const partnersIndex = () => BUILD_LEVEL === PRODUCTION ? "orgs" : "staging-orgs";

export const bookingsIndex = () => BUILD_LEVEL === PRODUCTION ? "bookings" : "staging-bookings";

export const eventsIndex = () => BUILD_LEVEL === PRODUCTION ? "events" : "staging-events";
